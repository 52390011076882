import React, { FC, memo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Heading } from '@ucheba/ui/components/Heading/desktop'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { EButtonColorByTheme, IBannerVariantProps } from '../types'

const Wrapper = styled.div<{ backgroundImage?: string | undefined }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  background-image: url('${({ backgroundImage }) => backgroundImage}');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
`

const StyledBox = styled.div<{ maxWidth: boolean }>`
  width: ${({ maxWidth }) => (maxWidth ? '65%' : '100%')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TextImage = styled.img`
  width: auto;
  max-height: 48px;
  max-width: 202px;
`

const StyledGridCol = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.div``

const StyledButtons = styled.div``

const AdvText = styled.div`
  position: absolute;
  left: -20px;
  top: 0;
  z-index: 999;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  width: 20px;
`

const TextAdv = styled(Text)`
  writing-mode: vertical-rl;
  transform-origin: center;
  transform: rotate(-180deg);
  letter-spacing: 1px;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

/** Компонент рекламный баннерр */
const BannerHorizontal: FC<IBannerVariantProps> = (props) => {
  const { spacing, content, pixelImg, companyPixelUrl } = props

  const {
    text,
    text_image_url,
    url,
    url_text,
    image_url,
    heading,
    color_theme,
    overTitle,
    clientName,
  } = content

  return (
    <Link href={url} rel='noindex nofollow' target='_blank'>
      {!!spacing?.top && <Spacing spacing={spacing.top} />}

      <Wrapper as={Box} color={color_theme} spacing='null' backgroundImage={image_url}>
        <StyledBox maxWidth={!!image_url} as={Box}>
          <Content>
            {overTitle && (
              <>
                <Spacing spacing='usmall' />

                <TextST uppercase>{overTitle}</TextST>

                <Spacing spacing='usmall' />
              </>
            )}

            <Heading h2={heading} spacing='xxsmall' />

            {text && <Description description={[text]} />}

            {pixelImg && (
              <img
                className='adv-pixel-img'
                src={pixelImg}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}

            {companyPixelUrl && (
              <img
                className='adv-companyPixel-img'
                src={companyPixelUrl}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}
          </Content>

          {url_text && (
            <StyledButtons>
              <Spacing spacing='small' />

              {text_image_url ? (
                <Grid spacing='xxsmall'>
                  <GridCol width='auto'>
                    <Button
                      rel='noindex nofollow'
                      href={url}
                      color={EButtonColorByTheme[color_theme]}
                    >
                      {url_text}
                    </Button>
                  </GridCol>

                  <StyledGridCol as={GridCol as any} width='auto'>
                    <TextImage src={text_image_url} />
                  </StyledGridCol>
                </Grid>
              ) : (
                <Button color={EButtonColorByTheme[color_theme]}>{url_text}</Button>
              )}
            </StyledButtons>
          )}
        </StyledBox>

        {clientName && (
          <AdvText>
            <Tooltip
              content={<Text color='black'>{clientName}</Text>}
              position='right-center'
              width='504px'
            >
              <TextAdv fontSize='xsmall1' lineHeight='xsmall1' color='gray25'>
                РЕКЛАМА
              </TextAdv>
            </Tooltip>
          </AdvText>
        )}
      </Wrapper>

      {!!spacing?.bottom && <Spacing spacing={spacing.bottom} />}
    </Link>
  )
}

export { BannerHorizontal }
export default memo(BannerHorizontal)
