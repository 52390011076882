import React, { FC, memo } from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import { Box } from '@ucheba/ui/components/Box/desktop'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { EButtonColorByTheme, IBannerVariantProps } from '../types'

const Image = styled.img`
  width: 100%;
`

const TextImage = styled.img`
  max-width: 204px;
  height: auto;
`

const TextImageWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Wrapper = styled.div`
  overflow: hidden;
`

const AdvText = styled.div`
  z-index: 999;
  height: 100%;
  cursor: default;
  display: flex;
  justify-content: center;
  padding: 8px 0;
`

const TextAdv = styled(Text)`
  letter-spacing: 1px;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

/** Компонент рекламный баннерр */
const BannerVertical: FC<IBannerVariantProps> = (props) => {
  const { content, pixelImg, companyPixelUrl } = props

  const {
    text,
    text_image_url,
    url,
    url_text,
    image_url,
    heading,
    color_theme,
    overTitle,
    clientName,
  } = content

  return (
    <>
      <Wrapper as={Box} color={color_theme} spacing='null'>
        <Link
          passHref
          href={url}
          rel='noindex nofollow'
          style={{ color: 'inherit' }}
          target='_blank'
        >
          <Box spacing='small'>
            {overTitle && (
              <>
                <Spacing spacing='usmall' />

                <TextST uppercase fontSize='small2'>
                  {overTitle}
                </TextST>

                <Spacing spacing='usmall' />
              </>
            )}

            <Text as='h3' fontSize='h3' lineHeight='h3' fontWeight='extrabold'>
              {heading}
            </Text>

            <Spacing spacing='xxsmall' />

            {pixelImg && (
              <img
                className='adv-pixel-img'
                src={pixelImg}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}

            {companyPixelUrl && (
              <img
                className='adv-companyPixel-img'
                src={companyPixelUrl}
                style={{
                  width: '1px',
                  height: '1px',
                  opacity: 0,
                  position: 'absolute',
                  top: -9999,
                  right: -9999,
                }}
                alt=''
              />
            )}

            {text && <Description description={[text]} />}

            {url_text && (
              <>
                <Spacing spacing='small' />

                <Button size='small' block color={EButtonColorByTheme[color_theme]}>
                  {url_text}
                </Button>
              </>
            )}

            {text_image_url && (
              <>
                <Spacing spacing='xxsmall' />

                <TextImageWrapper>
                  <TextImage src={text_image_url} />
                </TextImageWrapper>
              </>
            )}
          </Box>

          <Spacing spacing='xxsmall' />

          <Image src={image_url} />
        </Link>
      </Wrapper>

      {clientName && (
        <AdvText>
          <Tooltip
            content={<Text color='black'>{clientName}</Text>}
            position='left-center'
            width='500px'
          >
            <TextAdv
              textAlign='center'
              fontSize='xsmall1'
              lineHeight='xsmall1'
              color='gray25'
            >
              РЕКЛАМА
            </TextAdv>
          </Tooltip>
        </AdvText>
      )}
    </>
  )
}

export { BannerVertical }
export default memo(BannerVertical)
