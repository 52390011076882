import React, { FC, memo, useEffect } from 'react'
import styled, { StyledProps } from 'styled-components'
import Link from 'next/link'
import { Description } from '@ucheba/ui/components/Description/desktop'
import { Button } from '@ucheba/ui/components/Button/desktop'
import { Spacing } from '@ucheba/ui/components/Spacing/desktop'
import { Grid, GridCol } from '@ucheba/ui/components/Grid/desktop'
import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { Notice } from '@ucheba/ui/components/Notice/desktop'
import { Text } from '@ucheba/ui/components/Text/desktop'
import { Tooltip } from '@ucheba/ui/components/Tooltip/desktop'
import { sv } from '@ucheba/utils/helpers/styles/variables'
import { Box } from '@ucheba/ui/components/Box/touch'
import { bannerNoticeId } from '../constants'
import { EButtonColorByTheme, IBannerVariantProps } from '../types'
import SC from '../base/Banner.styles'

const { DotContainer, Dot, DotPulse } = SC

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  object-fit: contain;
  object-position: bottom;
`

const TextImage = styled.img`
  width: auto;
  max-height: 40px;
  max-width: 152px;
`

const StyledGridCol = styled.div`
  display: flex;
  align-items: center;
`

const Content = styled.div``

const StyledButtons = styled.div``

const StyledImageWrapper = styled.div`
  max-width: 114px;
  width: 100%;
  flex-shrink: 0;
  align-items: flex-end;
  display: flex;
`

const NoticeSC = styled(Notice)`
  position: relative;
  overflow: auto;
`

const AdvText = styled.div`
  position: absolute;
  right: -40px;
  top: 0;
  z-index: 999;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: default;
  width: 40px;
`

const TextAdv = styled(Text)`
  writing-mode: vertical-rl;
  letter-spacing: 1px;
`

const TextST = styled(Text)`
  letter-spacing: 1.4px;
`

const VideoBannerWrap = styled.div<StyledProps<any>>`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  cursor: ${(props) => (props.url ? 'pointer' : 'default')};
  min-width: 322px;
  background-color: ${(props) => sv.color[props.color] || sv.color.transparent};
`

/** Компонент рекламный баннерр */
const BannerNotice: FC<IBannerVariantProps> = (props) => {
  const { content, pixelImg, companyPixelUrl } = props

  const {
    text,
    text_image_url,
    url,
    url_text,
    image_url,
    heading,
    color_theme,
    overTitle,
    clientName,
    url_video,
    is_live,
  } = content

  const bannerNotice = useNotice(bannerNoticeId)

  useEffect(() => {
    if (!bannerNotice.open && content) {
      setTimeout(() => {
        bannerNotice.addNotice()
      }, 500)
    }
  }, [])

  return (
    <NoticeSC
      spacing={url_video ? 'null' : 'medium'}
      color={url_video && !heading ? 'gray60' : color_theme}
      hideProgress
      open={bannerNotice.open}
      overflow='visible'
    >
      <Link
        href={url}
        rel='noindex nofollow'
        style={{ color: 'inherit' }}
        target='_blank'
      >
        {url_video ? (
          <VideoBannerWrap color={color_theme}>
            {heading && (
              <Box color={color_theme} spacing='small'>
                <Text as='h3' fontSize='h3' fontWeight='extrabold'>
                  {heading}
                </Text>
              </Box>
            )}

            <video
              width='100%'
              preload='auto'
              autoPlay
              loop
              playsInline
              muted
              style={{
                cursor: 'pointer',
                borderRadius: '8px',
              }}
            >
              <source src={url_video} type='video/webm' />

              <source src={url_video} type='video/mp4' />
            </video>

            {url_text && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '100%',
                  padding: '16px',
                }}
              >
                <Button size='small' color='black' block>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {is_live && (
                      <div>
                        <DotContainer>
                          <Dot>
                            <DotPulse />
                          </Dot>
                        </DotContainer>
                      </div>
                    )}

                    <div>{url_text}</div>
                  </div>
                </Button>
              </div>
            )}
          </VideoBannerWrap>
        ) : (
          <Grid nowrap spacing='medium'>
            <GridCol shrink='initial' width='auto'>
              <Wrapper>
                <Content>
                  {overTitle && (
                    <>
                      <Spacing spacing='usmall' />

                      <TextST uppercase fontSize='small2'>
                        {overTitle}
                      </TextST>

                      <Spacing spacing='usmall' />
                    </>
                  )}

                  <Text as='h3' fontSize='h3' fontWeight='extrabold'>
                    {heading}
                  </Text>

                  <Spacing spacing='xxsmall' />

                  {text && <Description description={[text]} />}
                </Content>

                {url_text && (
                  <StyledButtons>
                    <Spacing spacing='small' />

                    {text_image_url ? (
                      <Grid spacing='xxsmall'>
                        <GridCol width='auto'>
                          <Button
                            size='small'
                            href={url}
                            color={EButtonColorByTheme[color_theme]}
                            rel='noindex nofollow'
                          >
                            {url_text}
                          </Button>
                        </GridCol>

                        <StyledGridCol as={GridCol as any} width='auto'>
                          <TextImage src={text_image_url} />
                        </StyledGridCol>
                      </Grid>
                    ) : (
                      <Button size='small' color={EButtonColorByTheme[color_theme]}>
                        {url_text}
                      </Button>
                    )}
                  </StyledButtons>
                )}
              </Wrapper>
            </GridCol>

            {image_url && (
              <StyledImageWrapper as={GridCol} width='auto'>
                <Image
                  src={image_url}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement
                    const parentElement = target.parentElement as HTMLDivElement

                    parentElement.style.display = 'none'
                  }}
                />
              </StyledImageWrapper>
            )}
          </Grid>
        )}
      </Link>

      {clientName && (
        <AdvText>
          <Tooltip
            content={<Text color='black'>{clientName}</Text>}
            position='left-center'
            width='500px'
          >
            <TextAdv fontSize='xsmall1' lineHeight='xsmall1' color='gray25'>
              РЕКЛАМА
            </TextAdv>
          </Tooltip>
        </AdvText>
      )}

      {pixelImg && (
        <img
          className='adv-pixel-img'
          src={pixelImg}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}

      {companyPixelUrl && (
        <img
          className='adv-companyPixel-img'
          src={companyPixelUrl}
          style={{
            width: '1px',
            height: '1px',
            opacity: 0,
            position: 'absolute',
            top: -9999,
            right: -9999,
          }}
          alt=''
        />
      )}
    </NoticeSC>
  )
}

export { BannerNotice }
export default memo(BannerNotice)
